import styled from 'styled-components';
// import AppColors from './colors';

export const SearchPageContainer = styled.div`
  padding: 80px 110px;
  @media (max-width: 1199px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const SearchInput = styled.input`
  position: relative;
  top: -3px;
  margin: 0 0 35px 0;
  padding: 11px 20px 11px 20px;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-appearance: none;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    margin-bottom: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

export const InputSubmit = styled.input`
  position: relative;
  top: 0;
  padding: 15px 40px 6px 30px;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: none;
  cursor: pointer;
  transition: all 0.25s ease-out;
  -webkit-appearance: none;
  margin-left: 0.4%;
  /* &:hover {
    color: #55aa4c;
    border-color: #55aa4c;
  } */
  @media (max-width: 767px) {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
    margin-bottom: 30px;
  }
`;

export const SearchResults = styled.div`
  border-top: 1px solid #f1f0ef;
  &:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const NoResults = styled.div`
  margin: 0;
  padding: 0;
  font-weight: bold;
  background-position: top center;
  color: #666;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-top: 32px;
`;
export const SearchResult = styled.div`
  margin: 0;
  padding: 40px 0 35px 0;
  border-bottom: 1px solid #f1f0ef;
  h3 {
    margin-bottom: 0;
    padding-bottom: 0;
    margin: 0 0 35px 0;
    font-family: 'Figtree', sans-serif;
    font-size: 28px;
    line-height: 37px;
    color: #00a6e8;
    @media (max-width: 1399px) {
      font-size: 25px;
      line-height: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 0;
      font-size: 20px;
      line-height: 25px;
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 4px;
    padding: 0;
    @media (max-width: 767px) {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const PageLink = styled.a`
  font-size: 14px;
  margin-bottom: 0;
  color: #999;
  margin: 2px 0 8px 0;
  padding: 0;
  display: block;
  word-break: break-all;
`;

export const PaginationDiv = styled.div`
  text-align: center;
  position: relative;
  margin-top: 20px;
`;

export const SearchAnchor = styled.a`
  font-size: 14px;
  margin-bottom: 0;
  color: #999;
  margin: 2px 0 8px 0;
  padding: 0;
  display: block;
  word-wrap: break-word;
`;

export const NoResultFound = styled.div`
  margin-top: 0 !important;
  padding: 20px 0;
  border: none;
  color: #55aa4c;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 35px;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
  }
`;

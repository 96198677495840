import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation, I18nextProvider } from 'react-i18next';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Pagination from 'react-js-pagination';
import Highlighter from 'react-highlight-words';
import * as Styled from '../styles/searchPageTemplateStyle';
import GlobalStyles from '../styles/globalStyles';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import TitleWithImage from '../components/titleWithImage/titleWithImage';
import Cookies from 'universal-cookie';
import i18next from '../i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getSearchData } from '../api/searchApi';
import { addTrailingSlash } from '../utils/index';

const SearchPageTemplate = ({ data, location }) => {
  const cookies = new Cookies();
  const [translation] = useTranslation();
  const selectedLanguage = cookies.get('language')
    ? cookies.get('language')
    : 'en-US';

  const regionLanguageFilteredData = data?.allContentfulSparlings?.edges?.find(
    (edge) => edge?.node?.node_locale === selectedLanguage
  );
  const sectionArray = regionLanguageFilteredData?.node?.sections[0];
  const params = new URLSearchParams(location?.search);
  const q = params?.get('q') || '';
  const [query, setQuery] = useState(q);
  const itemsPerpage = 5;
  const [activePage, setActivePage] = useState(0);
  const [results, setResults] = useState([]);
  const [currentElement, setCurrentElement] = useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const search = () => {
    getSearchData(q)
      .then(({ data }) => {
        const visitedSlugs = new Set();
        const filteredHit = data?.hits?.hit?.filter((obj) => {
          if (visitedSlugs?.has(obj?.fields?.slug)) {
            return false;
          } else {
            visitedSlugs.add(obj?.fields?.slug);
            return true;
          }
        });

        setResults(filteredHit);
        setCurrentElement(filteredHit?.slice(activePage, itemsPerpage));
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
        setCurrentElement([]);
      });
  };

  const getDataWithEllipsis = (content, content_length) => {
    const indexOfSearchInContent = content
      ?.toLowerCase()
      ?.indexOf(q?.toLowerCase());
    if (indexOfSearchInContent === -1) {
      return content
        ?.substr(0, content_length)
        .concat(content?.length > content_length ? '...' : '');
    }
    if (content.length - (indexOfSearchInContent + q.length) < content_length) {
      return content;
    }
    if (content.length - (indexOfSearchInContent + q.length) > content_length) {
      return indexOfSearchInContent > 3
        ? '...' +
            content?.substr(indexOfSearchInContent, content_length) +
            '...'
        : content?.substr(indexOfSearchInContent, content_length) + '...';
    }
    const data = content?.substr(
      Math.abs(indexOfSearchInContent),
      content_length + indexOfSearchInContent
    );
    return data;
  };

  useEffect(() => {
    if (query) {
      setLoading(true);
      search();
    }
  }, [location]);

  useEffect(() => {
    setCurrentElement(results?.slice(activePage, itemsPerpage));
  }, [results]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setCurrentElement(paginate(results, itemsPerpage, pageNumber));
  };

  const paginate = (array, page_size, page_number) => {
    if (!array) {
      return [];
    }
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    navigate(`/search/?q=${query}`);
  };

  const handleKeyUp = (event) => {
    if (event?.keyCode === 13 || event?.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleChange = (e) => {
    setQuery(e?.target?.value);
  };

  return (
    <>
      <GlobalStyles />
      <I18nextProvider i18n={i18next}>
        <Helmet
          htmlAttributes={{
            lang: regionLanguageFilteredData?.node?.node_locale || 'en',
          }}
        >
          <title>{translation('SearchTitle')}</title>
        </Helmet>
        <Header scrollPosition={scrollPosition} />
        <TitleWithImage sectionData={sectionArray} />
        <Styled.SearchPageContainer id="search-title">
          <form>
            <Styled.SearchInput
              name="query"
              type="text"
              value={query}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
            <Styled.InputSubmit
              name="submit"
              type="submit"
              value={translation('Go')}
              onClick={handleSubmit}
            />
          </form>

          {isError ? (
            <Styled.NoResults>
              Something went wrong Please try again
            </Styled.NoResults>
          ) : q ? (
            !isLoading ? (
              <Styled.SearchResults>
                {currentElement && currentElement?.length ? (
                  currentElement?.map(({ fields }, index) => {
                    return (
                      <Styled.SearchResult key={index}>
                        <h3>
                          <a
                            href={
                              process.env.GATSBY_DOMAIN_URL +
                              addTrailingSlash(fields?.slug)
                            }
                          >
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={fields?.title}
                            />
                          </a>
                        </h3>
                        <p>
                          {fields?.content_header && (
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={getDataWithEllipsis(
                                fields?.content_header,
                                100
                              )}
                            />
                          )}
                          {fields?.content_subtext && (
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={getDataWithEllipsis(
                                fields?.content_subtext,
                                100
                              )}
                            />
                          )}

                          {fields?.metadata && (
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={getDataWithEllipsis(
                                fields?.metadata,
                                100
                              )}
                            />
                          )}
                          {fields?.content_raw && (
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={getDataWithEllipsis(
                                fields?.content_raw,
                                200
                              )}
                            />
                          )}

                          <br />
                          <Styled.SearchAnchor
                            href={
                              process.env.GATSBY_DOMAIN_URL +
                              addTrailingSlash(fields?.slug)
                            }
                          >
                            <Highlighter
                              highlightClassName="highlightText"
                              searchWords={[q]}
                              autoEscape={true}
                              textToHighlight={
                                process.env.GATSBY_DOMAIN_URL +
                                addTrailingSlash(fields?.slug)
                              }
                            />
                          </Styled.SearchAnchor>
                        </p>
                      </Styled.SearchResult>
                    );
                  })
                ) : (
                  <>
                    <Styled.NoResultFound>
                      {translation('NoResultFound')}
                    </Styled.NoResultFound>
                  </>
                )}
              </Styled.SearchResults>
            ) : (
              <Styled.NoResultFound>Loading...</Styled.NoResultFound>
            )
          ) : (
            <Styled.NoResultFound>
              Please Enter a keyword to search
            </Styled.NoResultFound>
          )}
          {q && !isError ? (
            currentElement && currentElement?.length ? (
              <Styled.PaginationDiv>
                <Pagination
                  firstPageText=""
                  lastPageText=""
                  prevPageText="← Previous"
                  nextPageText="Next →"
                  activePage={activePage ? activePage : 1}
                  itemsCountPerPage={5}
                  totalItemsCount={results?.length}
                  pageRangeDisplayed={5}
                  onChange={(e) => {
                    handlePageChange(e);
                    const scrollID = document?.getElementById('search-title');
                    if (scrollID) {
                      scrollID.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  itemClass="itemClass"
                  itemClassPrev="first"
                  itemClassNext="last"
                  disabledClass="disabled"
                  linkClass="linkClass"
                  activeLinkClass="activeLinkClass"
                  linkClassFirst="linkClassFirst"
                  linkClassLast="linkClassLast"
                />
              </Styled.PaginationDiv>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Styled.SearchPageContainer>
        <Footer />
      </I18nextProvider>
    </>
  );
};

export default SearchPageTemplate;

export const pageQuery = graphql`
  query SearchPageMyQuery {
    site {
      siteMetadata {
        title
      }
    }
    LunrIndex

    allContentfulSparlings(
      filter: { identifier: { type: { eq: "searchResult" } } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          contentModels
          sections {
            ... on ContentfulTextWithImage {
              id
              title
              type
              text {
                text
              }
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
